
import { defineComponent, ref } from 'vue'
import { loadScript } from '@/utils/loadScript'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Worldpay: any

const styles = {
  input: {
    'font-size': '1.125rem',
    'font-family': 'Roboto, sans-serif'
  }
}
const fields = {
  pan: {
    selector: '#card-pan',
    placeholder: '0000 0000 0000 0000'
  },
  expiry: {
    selector: '#card-expiry',
    placeholder: 'MM / YY'
  },
  cvv: {
    selector: '#card-cvv'
  }
}
const accessibility = {
  ariaLabel: {
    pan: 'Credit card number',
    expiry: 'Expiry date',
    cvv: 'Security number (CVV)'
  },
  lang: {
    locale: 'en-GB'
  },
  title: {
    enabled: true,
    pan: 'Card number',
    expiry: 'Expiry date',
    cvv: 'CVV'
  }
}

export default defineComponent({
  emits: [
    'tokenizedCard',
    'sessionizationFailed'
  ],
  setup (_, { emit }) {
    const tokenizeCard = ref()
    loadScript(process.env.VUE_APP_WORLDPAY_CHECKOUT_SDK_URL)
      .then(() => {
        const id = process.env.VUE_APP_WORLDPAY_ACCOUNT_ID
        Worldpay.checkout.init(
          {
            id: id,
            styles: styles,
            form: '#card-form',
            fields: fields,
            accessibility: accessibility,
            enablePanFormatting: true
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (err: unknown, checkout: any) => {
            if (err) {
              emit('sessionizationFailed')
              return
            }
            tokenizeCard.value = () => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              checkout.generateSessions((err: unknown, sessions: any) => {
                if (err) {
                  emit('sessionizationFailed')
                  return
                }

                emit('tokenizedCard', sessions.card, sessions.cvv)
              })
            }
          }
        )
      })

    return {
      tokenizeCard
    }
  }
})
