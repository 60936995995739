
import { defineComponent, ref } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    validator: {
      type: Object,
      required: true
    }
  },
  setup () {
    const showError = ref(false)
    return { showError }
  }
})
