
import { ValidationRuleWithParams } from '@vuelidate/core'
import { defineComponent, PropType } from 'vue'

interface RangeValidator {
  minValue: ValidationRuleWithParams<{ min: number }>;
  maxValue: ValidationRuleWithParams<{ max: number }>;
}

export default defineComponent({
  name: 'MonthYearSelector',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    yearValidator: {
      type: Object as PropType<RangeValidator>,
      required: true
    },
    monthValidator: {
      type: Object as PropType<RangeValidator>,
      required: true
    },
    separator: {
      type: String,
      required: false,
      default: '/'
    },
    disabled: Boolean,
    monthAutocomplete: String,
    yearAutocomplete: String
  },
  setup (props) {
    const rangeToOptions = (min: number, max: number) =>
      Array(max - min + 1).fill(0).map((_, i) => (i + min))

    const yearOptions = rangeToOptions(
      props.yearValidator.minValue.$params.min,
      props.yearValidator.maxValue.$params.max
    )
    const monthOptions = rangeToOptions(
      props.monthValidator.minValue.$params.min,
      props.monthValidator.maxValue.$params.max
    )

    return {
      yearOptions,
      monthOptions
    }
  }
})
