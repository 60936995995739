
import { downloadBasket } from '@/expressway-api/baskets.api'
import { defineComponent, ref } from 'vue'
import Spinner from '@/components/Spinner.vue'
import { setSnackbar } from '@/composables/useSnackbar'

export default defineComponent({
  components: { Spinner },
  props: {
    basketId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const loading = ref(false)

    const download = () => {
      loading.value = true
      downloadBasket(props.basketId).then(response => {
        const link = document.createElement('a')
        link.href = `data:application/octet-stream;base64,${response.data}`
        link.setAttribute('download', 'Expressway Ticket.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(() => setSnackbar('Error downloading tickets'))
        .finally(() => { loading.value = false })
    }

    return {
      download,
      loading
    }
  }
})
