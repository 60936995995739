import { ref, computed } from 'vue'

export default function useTabs (tabNames: string[], initialTabName?: string) {
  const activeTabIndex = ref(0)
  const tabCount = tabNames.length

  const activeTab = computed({
    get: () => tabNames[activeTabIndex.value],
    set: tabName => {
      activeTabIndex.value = tabNames.indexOf(tabName)
    }
  })

  const nextTab = () => {
    activeTabIndex.value = (activeTabIndex.value + 1) % tabCount
  }

  const previousTab = () => {
    activeTabIndex.value = (activeTabIndex.value - 1 + tabCount) % tabCount
  }

  if (initialTabName) activeTab.value = initialTabName

  return {
    activeTab,
    activeTabIndex,
    nextTab,
    previousTab
  }
}
