const loadScript = async (src: string) =>
  new Promise(
    (resolve, reject) => {
      let shouldAppend = false
      let el: HTMLScriptElement | null = document.querySelector(`script[src='${src}']`)

      if (!el) {
        el = document.createElement('script')
        el.type = 'text/javascript'
        el.async = true
        el.src = src
        shouldAppend = true
      } else if (el.hasAttribute('data-loaded')) {
        resolve(el)
        return
      }

      el.addEventListener('error', reject)
      el.addEventListener('abort', reject)
      el.addEventListener('load', function loadScriptHandler () {
        this.setAttribute('data-loaded', 'true')
        resolve(el)
      })

      if (shouldAppend) document.head.appendChild(el)
    }
  )

export { loadScript }
