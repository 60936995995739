<template>
  <svg
    width="13"
    height="9"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.625 4.5C5.83125 4.5 6.8125 3.51875 6.8125 2.3125C6.8125 1.10625 5.83125 0.125 4.625 0.125C3.41875 0.125 2.4375 1.10625 2.4375 2.3125C2.4375 3.51875 3.41875 4.5 4.625 4.5ZM0.25 7.78125C0.25 6.325 3.1625 5.59375 4.625 5.59375C6.0875 5.59375 9 6.325 9 7.78125V8.875H0.25V7.78125ZM4.62501 6.84375C3.50626 6.84375 2.23751 7.2625 1.71251 7.625H7.53751C7.01251 7.2625 5.74376 6.84375 4.62501 6.84375ZM5.5625 2.3125C5.5625 1.79375 5.14375 1.375 4.625 1.375C4.10625 1.375 3.6875 1.79375 3.6875 2.3125C3.6875 2.83125 4.10625 3.25 4.625 3.25C5.14375 3.25 5.5625 2.83125 5.5625 2.3125ZM9.02499 5.63125C9.74999 6.15625 10.25 6.85625 10.25 7.78125V8.875H12.75V7.78125C12.75 6.51875 10.5625 5.8 9.02499 5.63125ZM10.5625 2.3125C10.5625 3.51875 9.58125 4.5 8.375 4.5C8.0375 4.5 7.725 4.41875 7.4375 4.28125C7.83125 3.725 8.0625 3.04375 8.0625 2.3125C8.0625 1.58125 7.83125 0.9 7.4375 0.34375C7.725 0.20625 8.0375 0.125 8.375 0.125C9.58125 0.125 10.5625 1.10625 10.5625 2.3125Z"
      fill="black"
    />
  </svg>
</template>
